<template>
    <div class="performances">
        <Logo />
        <div class="box buddha-bg">
            <div class="header">
                <a-icon class="back" @click="$router.go(-1)" type="left-circle" />
                <div class="title">
                    <span class="text">共修業績</span>
                </div>
            </div>
            <div class="container-pd">
                <div class="total">
                    {{ pdata.total.toLocaleString() }}
                </div>
                <div class="box min-box">
                    <div class="sub-title">
                        <span>小區業績：</span><span>{{ pdata.min.toLocaleString() }}</span>
                    </div>
                    <div class="flex items">
                        <div class="item" v-for="(x,i) in pdata.minKpi" :key="'mi'+i">
                            <div>{{ x }}</div>
                            <div>{{ $t('profile.nodesText')[i+1] }}</div>
                        </div>
                    </div>
                </div>
                <div class="box max-box">
                    <div class="sub-title">
                        <span>大區業績：</span><span>{{ (pdata.total - pdata.min).toLocaleString() }}</span>
                    </div>
                    <div class="flex items">
                        <div class="item" v-for="(x,i) in pdata.maxKpi" :key="'ma'+i">
                            <div>{{ x }}</div>
                            <div>{{ $t('profile.nodesText')[i+1] }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Logo from '@/components/Logo.vue';
export default {
    name: "Performances",
    data() {
        return {
            pdata: {
                total: 0,
                min: 0,
                minKpi: [0,0,0,0,0],
                maxKpi: [0,0,0,0,0],
            }
        };
    },
    components: { Logo },
    mounted() {
        this.getData();
    },
    methods: {
        getData(){
            // this.loading = true;
            this.$http.call(this.$http.api.performance).then(res=>{
                console.log(res);
                // this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    this.pdata = resp.data;
                }
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.performances {
    width:100%;
    height: 100%;
    padding: 0 50px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    .logo {
        padding: 20PX 0;
    }
    .box{
        position: relative;
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 30PX;
        .header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15PX;
            .back {
                position: absolute;
                left: 15PX;
                font-size: 20PX;
                color: #a8a8a8;
            }
            .title {
                font-weight: bold;
                font-size: 16PX;
                color: @primary-color;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .title-img {
                    height: 50PX;
                }
            }
        }
        .container-pd {
            overflow-y: scroll;
            overflow-x: hidden;
            .total {
                font-size: 100px;
                margin-bottom: 24px;
            }
            .box {
                .sub-title {
                    padding: 24px 0;
                }
                
                .items {
                    padding: 24px 0 50px;
                    .item {
                        flex: 1;
                        text-align: center;
                    }
                    
                }
            }
        } 
    }
}
</style>
